import classnames from 'classnames';
import { useUUID } from '@medifind/router';
import styles from './CheckBox.module.scss';

const Checkbox = ({ label, onChange, value, classes = {}, style = {}, noLabel = false, checked = false, type = 'checkbox', labelId }) => {
  const uuid = useUUID();
  return (
    <div className={classnames(styles['checkbox'], classes.wrapper)} style={style.wrapper}>
      <input
        className={classnames(styles['checkbox__input'], classes.input)}
        style={style.input}
        value={value}
        onChange={onChange}
        type={type}
        id={uuid}
        {...(noLabel ? { 'aria-label': label } : {})}
        checked={checked}
      />
      {!noLabel && (
        <label className={classnames(styles['checkbox__label'], classes.display)} htmlFor={uuid} style={style.display}>
          {label}
        </label>
      )}
    </div>
  );
};

export { Checkbox as default, Checkbox };
