import { abortableFetch, clientFetch } from '@medifind/interface';

export const getSubmittedInfusionCenters = (body) => {
  return clientFetch(`/user/profile-management/get-submitted-infusion-centers`, { method: 'POST', body });
};

export const getPendingInfusionCenterApprovals = (params) => {
  return clientFetch(`/user/profile-management/get-pending-infusion-center-approvals`, { method: 'GET', params });
};

export const approvePendingInfusionCenterRecord = ({ recordId }) => {
  return clientFetch(`/user/profile-management/approve-infusion-center-record`, { method: 'PUT', body: { recordId } });
};

export const rejectPendingInfusionCenterRecord = (payload) => {
  return clientFetch(`/user/profile-management/reject-infusion-center-record`, { method: 'POST', body: payload });
};

export const getPendingInfusionCenterApprovalsByInternalId = ({ internalId }) => {
  return clientFetch(`/user/profile-management/get-pending-infusion-center-approvals/${internalId}`, { method: 'GET' });
};

export const saveAndApproveInfusionCenterByRecordId = ({ recordId, payload }) => {
  return clientFetch(`/user/profile-management/save-and-approve-infusion-center/${recordId}`, { method: 'PUT', body: { payload } });
};

export const publishInfusionCenterByInternalId = ({ internalId }) => {
  return clientFetch(`/user/profile-management/publish-infusion-center/${internalId}`, { method: 'PUT' });
};

export const icAutocomplete = (params) => {
  return clientFetch(`/infusion-center/autocomplete`, { method: 'GET', params });
};

export const getMasterMetadata = () => {
  return clientFetch(`/infusion-center/masterMetadata`, { method: 'GET' });
};

export const getPendingUpdates = (params) => {
  return clientFetch(`/infusion-center/pendingUpdates`, { method: 'GET', params });
};

export const getICbyInternalId = (params) => {
  return clientFetch(`/infusion-center/entity`, { method: 'GET', params });
};

export const getInfusionCentersResults = (body) => {
  return abortableFetch('/infusion-center/search', { method: 'POST', body }, 'infusionCenterSearchController');
};

export const getInfusionCenters = ({ payload }) => {
  return clientFetch(`/infusion-center/search`, { method: 'POST', body: { payload } });
};

export const addInfusionCenter = ({ payload }) => {
  return clientFetch(`/infusion-center/add`, { method: 'POST', body: { payload } });
};

export const updateInfusionCenter = ({ internalId, payload }) => {
  return clientFetch(`/infusion-center/update/${internalId}`, { method: 'PUT', body: { payload } });
};
